import Glide from '@glidejs/glide';
import { setRangeMax, handleCarouselButtons, handlePadding } from '../../../helpers/carouselRange';

const mediaCarousels: NodeListOf<HTMLDivElement> = document.querySelectorAll('.media-carousel__inner');

mediaCarousels.forEach((item) => {
    var glide = new Glide(item, {
        type: 'carousel',
        perView: 3,
        gap: 40,
        breakpoints: {
            1380: {
                type: 'carousel',
                perView: 3,
                gap: 40,
                rewind: false,
                bound: false,
            },
            1180: {
                type: 'carousel',
                perView: 2,
                gap: 40,
                rewind: false,
                bound: false,
            },
            900: {
                type: 'carousel',
                perView: 1,
                gap: 30,
                rewind: false,
                bound: false,
            },
            700: {
                type: 'carousel',
                perView: 1,
                gap: 30,
                rewind: false,
                bound: false,
            },
            550: {
                type: 'slider',
                perView: 1,
                gap: 30,
                rewind: false,
                bound: false,
            },
            480: {
                type: 'slider',
                perView: 1,
                gap: 30,
                rewind: false,
                bound: false,
            },
        },
    });

    const carouselRange = item.querySelector('.media-carousel__range') as HTMLInputElement;

    const carouselContainer = item.parentElement as HTMLElement;
    const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.media-carousel__item');
    setRangeMax(slides, carouselRange, glide.settings.perView);
    handleCarouselButtons(slides, glide.settings.perView, item, glide);
    handlePadding(slides, glide.settings.perView, carouselContainer);

    glide.mount();

    carouselRange.addEventListener('input', (e) => {
        setTimeout(() => {
            glide.go(`=${carouselRange.value}`);
        }, 500);
    });

    glide.on('move.after', () => {
        const currentIndex = glide.index;
        carouselRange.value = currentIndex;
    });

    glide.on('resize', () => {
        setRangeMax(slides, carouselRange, glide.settings.perView);
        handleCarouselButtons(slides, glide.settings.perView, item, glide);
        console.log(slides, glide.settings.perView, item, glide)
        handlePadding(slides, glide.settings.perView, carouselContainer);
        glide.go(`=0`);
    });

    // Covers the phantom gap that appears in the carousel when there are less than 3 slides
    if(slides.length < 3) {
        const carouselItems: NodeListOf<HTMLElement> = item.querySelectorAll('.page-carousel__item');

        carouselItems.forEach((item) => {
            item.style.flex = "1";
        })
    }
});

// mediaCarousels.forEach(carousel => {
//     const playButtons: NodeListOf<HTMLElement> = carousel.querySelectorAll('.media-carousel__button');
//     const videos: NodeListOf<HTMLVideoElement> = carousel.querySelectorAll('.modal-video__video');
//     const modals: NodeListOf<HTMLElement> = carousel.querySelectorAll('.modal-video');

//     playButtons.forEach((button, index) => {
//         button.addEventListener('click', () => {
//             const video = videos[index] as HTMLVideoElement;
//             const modal = modals[index] as HTMLDialogElement;

//             modal?.showModal();
//             video?.play();
//         });
//     });

//     window.addEventListener('click', event => {
//         videos.forEach(video => {
//             if (video && (event.target as Element).className == 'modal-video') {
//                 closeVideoModal(video);
//             }

//             if (video && (event.target as Element).id == 'modal-video__close') {
//                 closeVideoModal(video);
//             }

//             if ((event.target as Element).id == 'modal-video__close-icon') {
//                 closeVideoModal(video);
//             }
//         });
//     });

//     function closeVideoModal(video: HTMLVideoElement) {
//         video.pause();
//         video.currentTime = 0;

//         modals.forEach(modal => {
//             const modalElement = modal as HTMLDialogElement;

//             modalElement.close();
//         });
//     }
// });
