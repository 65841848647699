const paginationElements = document.querySelectorAll(".pagination");

export function updatePagination(page: number, allPages: number, totalItems: number, itemsPerPage: number, hasNextPage: boolean, hasPreviousPage: boolean, showingResults: number) {
  paginationElements.forEach(item => {
    if (totalItems < 1) {
      item.classList.add("pagination--hidden");
    } else {
      item.classList.remove("pagination--hidden");
    }
    const element: HTMLElement = item.querySelector(".pagination__inner");
    const text: HTMLElement = item.querySelector(".pagination__text");

    if (totalItems > itemsPerPage || showingResults < totalItems) {
      text.textContent = `Showing ${showingResults} of ${totalItems} results`
    } else {
      text.textContent = `Showing ${totalItems} of ${totalItems} results`
    }

    const totalPages = allPages;

    element.innerHTML = createPagination(totalPages, page, hasNextPage, hasPreviousPage);
    disablePagination(element)
  });
}

function createPagination(
  totalPages: number,
  page: number,
  hasNextPage: boolean,
  hasPreviousPage: boolean
) {
  let liTag: string = "";
  let active: string = "";
  let renderedPages: Set<any> = new Set();

  if (totalPages === 1) {
    const paginationInner = document.querySelector(".pagination__inner") as HTMLElement;
    if (paginationInner) {
      paginationInner.style.display = "none";
    }
    return ``;
  }

  if (totalPages === 2) {
    return `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__numb ${page === 1 ? 'pagination__btn--active' : ''}">1</button><button class="pagination__btn flex flex--align-center flex--justify-center pagination__numb ${page === 2 ? 'pagination__btn--active' : ''}">2</button>`;
  }

  // Add the "Previous" button
  if (page > 1 && hasPreviousPage) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__arrow prev"><i class="zmdi zmdi-chevron-left"></i></button>`;
  }

  // Always show the first 3 pages
  for (let i = 1; i <= Math.min(3, totalPages); i++) {
    if (!renderedPages.has(i)) {
      active = page === i ? "pagination__btn--active" : "";
      liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__numb ${active}">${i}</button>`;
      renderedPages.add(i); // Mark this page as rendered
    }
  }

  // Add ellipsis after the first 3 pages if necessary
  if (page > 4 && totalPages > 6 && !renderedPages.has("...")) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__dots" tabindex="-1">...</button>`;
  }

  // Add the middle range pages
  const startMiddleRange = Math.max(4, page - 1);
  const endMiddleRange = Math.min(totalPages - 2, page + 1);
  for (let i = startMiddleRange; i <= endMiddleRange; i++) {
    if (!renderedPages.has(i)) {
      active = page === i ? "pagination__btn--active" : "";
      liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__numb ${active}">${i}</button>`;
      renderedPages.add(i);
    }
  }

  // Add ellipsis before the last 3 pages if necessary
  if (page < totalPages - 3 && totalPages > 6 && !renderedPages.has("...")) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__dots" tabindex="-1">...</button>`;
  }

  // Always show the last 3 pages
  for (let i = totalPages - 2; i <= totalPages; i++) {
    if (!renderedPages.has(i)) {
      active = page === i ? "pagination__btn--active" : "";
      liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__numb ${active}">${i}</button>`;
      renderedPages.add(i);
    }
  }

  // Add the "Next" button
  if (page < totalPages && hasNextPage) {
    liTag += `<button class="pagination__btn flex flex--align-center flex--justify-center pagination__arrow next"><i class="zmdi zmdi-chevron-right"></i></button>`;
  }

  return liTag;
}

export function disablePagination(element: HTMLElement) {
  const buttons: NodeListOf<HTMLButtonElement> = element.querySelectorAll("button");

  buttons.forEach(button => {
    button.disabled = true;
  });
}

export function enablePagination(element: HTMLElement) {
  const buttons: NodeListOf<HTMLButtonElement> = element.querySelectorAll("button");

  buttons.forEach(button => {
    button.disabled = false;
  });
}