const mobileMenuIcon = document.querySelector(".menu-mobile__icon") as HTMLElement
const mobileMenuButton = document.querySelector(".header__button") as HTMLElement
const mobileMenu = document.querySelector(".menu-mobile__overlay") as HTMLElement
const html = document.querySelector("html") as HTMLElement

document.addEventListener('DOMContentLoaded', function() {
  setupMenuItems();
  setupBackButton();
  setupMenuIcon();
  setupMenuButton();
});

function setupMenuItems() {
  document.querySelectorAll('.menu-mobile__item').forEach(item => {
    item.addEventListener('click', function() {
      const itemId = (this as HTMLElement).getAttribute('data-item-id');
      openNestedLinks(itemId);
    });
  });
}

function setupBackButton() {
  document.querySelectorAll('.menu-mobile__back-button').forEach(backButton => {
      backButton.addEventListener('click', function(event) {
          handleBackButtonClick(event);
      });
  });
}

function setupMenuIcon() {
  if (mobileMenuIcon) {
    mobileMenuIcon.addEventListener("click", handleMenuIcon);
  }
}

function setupMenuButton() {
  mobileMenuButton?.addEventListener("keydown", e => {
    if (e.key === "Enter") {
      handleMenuIcon(e);
    }
  });

  mobileMenu?.addEventListener("keydown", e => {
    if (e.key === "Escape") {
      handleMenuIcon(e);
    }
  });
}

function openNestedLinks(itemId: string | null) {
  document.querySelectorAll('.menu-mobile__item').forEach(item => {
  
      if (item.getAttribute('data-item-id') !== itemId) {
          (item as HTMLElement).classList.add('menu-mobile__item--hidden');
      }
  });

  const nestedLinks = document.querySelector(`[data-item-id='${itemId}']`) as HTMLElement | null;
  if (nestedLinks) {
      nestedLinks.classList.remove('menu-mobile__item--hidden');
      const link = nestedLinks.querySelector('.menu-mobile__nested-links') as HTMLElement;
      link.style.display = 'flex';
  }

  document.querySelectorAll('.menu-mobile__title').forEach(titleElement => {
      (titleElement as HTMLElement).style.display = 'none';
  });
}


function handleMenuIcon(event?: Event) {
  closeNestedLinks();
  if (event) {
    event.stopPropagation();
  }
  toggleMenuIcon();
}

function handleBackButtonClick(event) {
  closeNestedLinks();
  event.stopPropagation();
}

function closeNestedLinks() {
  document.querySelectorAll('.menu-mobile__item').forEach(item => {
      (item as HTMLElement).classList.remove('menu-mobile__item--hidden');
  });

  document.querySelectorAll('.menu-mobile__nested-links').forEach(nestedLink => {
      (nestedLink as HTMLElement).style.display = 'none';
  });

  document.querySelectorAll('.menu-mobile__title').forEach(titleElement => {
      (titleElement as HTMLElement).style.display = 'block';
  });
}

function toggleMenuIcon() {
  mobileMenuIcon.classList.toggle("menu-mobile__icon--open");
  mobileMenu.classList.toggle("menu-mobile__overlay--active");
  html.style.overflow = mobileMenuIcon.classList.contains("menu-mobile__icon--open") ? "hidden" : "auto";
}