import Glide from '@glidejs/glide';

const largeIconGrid: NodeListOf<HTMLElement> = document.querySelectorAll(".large-icons-grid__carousel-inner");

largeIconGrid.forEach(item => {
  const cards: NodeListOf<HTMLElement> = item.querySelectorAll(".large-icons-grid__carousel-card");
  const carousel = item.parentElement as HTMLElement;

  let mobilePerView = 1.2 as number;

  if (cards.length < 2) {
    mobilePerView = 1;

    carousel.classList.add("large-icons-grid__carousel--padding-right");
  }

  var glide = new Glide(item, {
    type: 'slider',
    perView: 3.5,
    gap: 20,
    rewind: false,
    breakpoints: {
      1200: {
        perView: 2.5,
      },
      760: {
        perView: 1.7,
      },
      550: {
        perView: mobilePerView,
      },
    }
  })

  const largeIconsCarouselRange = document.querySelector(".large-icons-grid__carousel-range") as HTMLInputElement
  const items: NodeListOf<HTMLElement> = item.querySelectorAll(".large-icons-grid__carousel-card")
  const itemCount = items.length as number;

  if (itemCount == 1) {
    const rightArrow = item.querySelector('.large-icons-grid__carousel-arrow-right') as HTMLElement;
    const leftArrow = item.querySelector('.large-icons-grid__carousel-arrow-left') as HTMLElement;

    rightArrow.classList.add('hidden');
    leftArrow.classList.add('hidden');
  }

  glide.mount()

  largeIconsCarouselRange.addEventListener("input", e => {
    setTimeout(() => {
      glide.go(`=${largeIconsCarouselRange.value}`)
    }, 500);
  })

  glide.on('move.after', () => {
    const largeIconsCurrentIndex = glide.index;
    largeIconsCarouselRange.value = largeIconsCurrentIndex
    const rightButton = item.querySelector(".large-icons-grid__carousel-arrow-right") as HTMLElement;

    if (largeIconsCurrentIndex > itemCount - 1) {
      rightButton.classList.add('glide__arrow--disabled');
    } else if (largeIconsCurrentIndex < itemCount - 1) {
      rightButton.classList.remove('glide__arrow--disabled');
    }
  })
})

if (largeIconGrid) {
  largeIconGrid.forEach(item => {
    const slides: NodeListOf<HTMLElement> = item.querySelectorAll(".large-icons-grid__carousel-card")
    const largeIconsCarouselRange = item.querySelector(".large-icons-grid__carousel-range") as HTMLInputElement

    setRangeMax(slides, largeIconsCarouselRange)
  })
}

function setRangeMax(arr, input) {
  const slidesLength = Array.from(arr).length
  const rangeMax = (slidesLength - 1).toString()
  input.max = rangeMax

  setRangeThumbWidth(rangeMax, input)
}

function setRangeThumbWidth(max, input) {
  const width = 200 / +max
  input.style.setProperty('--thumb-size', width.toString() + "px");

  if (max === "1") {
    input.style.setProperty('--thumb-size', "100px");
  }
}