import Glide from '@glidejs/glide';
import { setRangeMax, handleCarouselButtons } from '../../../helpers/carouselRange';

const testimonialsCarousels: NodeListOf<HTMLElement> = document.querySelectorAll(".testimonials__inner")

testimonialsCarousels.forEach(item => {
  var glide = new Glide(item, {
    type: 'slider',
    perView: 1,
    gap: 60,
    rewind: true,
    breakpoints: {
      480: {
        perView: 1.2,
        gap: 20,
      },
    }
  })

  glide.mount()

  const carouselRange = item.querySelector(".testimonials__range") as HTMLInputElement

  carouselRange.addEventListener("input", e => {
    setTimeout(() => {
      glide.go(`=${carouselRange.value}`)
    }, 500);
  })

  const slides: NodeListOf<HTMLDivElement> = item.querySelectorAll('.testimonials__item');
  setRangeMax(slides, carouselRange, glide.settings.perView);
  handleCarouselButtons(slides, glide.settings.perView, item, glide);

  glide.on('move.after', () => {
    const currentIndex = glide.index;
    carouselRange.value = currentIndex
  })
})