const videoContainerComponent = document.querySelectorAll(".video")

if(videoContainerComponent){
const videoContainerComponentArray = Array.from(videoContainerComponent)

videoContainerComponentArray.forEach(item => {
  const videoComponentButton = item.querySelector(".video__button") as HTMLElement
  const videoComponentVideo = item.querySelector(".video__video") as HTMLVideoElement

    item.addEventListener("click", e => {
    if(!videoComponentVideo.classList.contains("video__video--active"))
    {
      showVideoComponentVideo()
    }
  })

  videoComponentButton.addEventListener("click", e =>{
    if(!videoComponentVideo.classList.contains("video__video--active"))
    {
      showVideoComponentVideo()
    }
  })

    function showVideoComponentVideo() {
    videoComponentButton.classList.remove("video__button--active")
    videoComponentVideo.classList.add("video__video--active")
    videoComponentVideo.setAttribute("controls", "controls")
    videoComponentVideo.play()
    }
  })
}