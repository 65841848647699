import selectedFilters from "../filterElement/filterElement";
import { updatePagination, disablePagination, enablePagination } from "../pagination/pagination";
const cardGrid = document.querySelector(".card-grid") as HTMLElement;

if (cardGrid) {
  const cardGridFilters = cardGrid.querySelector(".card-grid__filters") as HTMLElement;
  const cardGridTrigger = cardGrid.querySelector(".card-grid__trigger") as HTMLElement;
  const paginationElement = cardGrid.querySelector(".pagination") as HTMLElement;
  const grid = cardGrid.querySelector(".card-grid__grid") as HTMLElement;
  const labels: NodeListOf<HTMLElement> = cardGrid.querySelectorAll('.filters__dropdown-content label');

  const parentId = cardGrid.getAttribute("data-parent-id") as string;

  let types: Array<any> = [];
  let topics: Array<any> = [];
  let pageSize: number = 8;

  let pageNumber: number = 1;
  let hasNextPage: boolean = false;
  let hasPreviousPage: boolean = false;
  let totalCount: number = 0;
  let totalPages: number = 0;
  let showingResults: number = 0;
  let cards: Array<any> = [];

  const baseURL = window.location.origin;

  const fetchData = async () => {
    disablePagination(paginationElement);
    disableFilters();
    getFilters();
    const featureString = generateFilterString("featureTags", topics);
    const typeString = generateFilterString("mediaTypes", types);
    let url = `${baseURL}/umbraco/api/imagecards/GetChildPages?parentId=${parentId}&page=${pageNumber}&pageSize=${pageSize}`;
    if(featureString !== "" ) {
      url = url + featureString;
    }
    else if (typeString !== "") {
      url = url + typeString;
    }
    
    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      
      const data = await response.json();
      cards = data.items;
      const featureCards = data.featureCards.filter(card => {
        return topics.length === 0 || topics.some(topic => card.featureTag.includes(topic));
      });
      
      setPaginationData(data);
      renderCards(cards, featureCards);
      enablePagination(paginationElement);
      enableFilters();
      setTimeout(() => {
        window.scrollTo({ top: 0 }); // Scroll to the top smoothly
      }, 10);
    } catch (error) {
      grid.innerHTML = "<span class='flex flex--align-center flex--justify-center card-grid__info'>There was an error</span>";
      console.error('There was a problem with the fetch operation:', error);
    }
  };
  
  const setPaginationData = (data: any) => {
    totalPages = data.totalPages;
    totalCount = data.totalItems;
    hasNextPage = data.hasNextPage;
    hasPreviousPage = data.hasPreviousPage;
    pageSize = data.pageSize;
    showingResults = data.showingResults;
    updatePagination(pageNumber, totalPages, totalCount, pageSize, hasNextPage, hasPreviousPage, showingResults);
  };

  const renderCards = (cards: Array<any>, featureCards: Array<any>) => {
    grid.innerHTML = "";

    let cardIndex = 0;
    let featureCardIndex = 0;

    while (cardIndex < cards.length || featureCardIndex < featureCards.length) {
      // Add 3 item cards
      for (let i = 0; i < 3 && cardIndex < cards.length; i++) {
        const card = cards[cardIndex];
        const linkName = card.linkNameOverride && card.linkNameOverride.trim() !== ""
          ? card.linkNameOverride
          : card.link.name;

        const cardElement = document.createElement("a");
        cardElement.href = card.link.url;
        cardElement.className = "card-grid__card flex flex--column";

        cardElement.innerHTML = `
          ${card.imageUrl ? `
            <div class='card-grid__card-image-container'>
              <img src='${baseURL}${card.imageUrl}' alt='${card.title}' class='card-grid__card-image' />

              ${card.featureTag ? `
                <div class='card-grid__card-badge'>
                  ${card.featureTag}
                </div>`
              : ""}

            </div>`
          : ""}
          <div class='card-grid__card-content flex flex--column flex--align-start'>
          ${card.featureTag && !card.imageUrl ? `
            <div class='card-grid__card-badge' style='position: relative; top: -5px; left: -20px;'>
              ${card.featureTag}
            </div>`
          : ""}
            <div class="card-grid__card-row flex flex--wrap flex--justify-between flex--align-center">

            ${card.date ? `
              <span class='card-grid__card-date'>
                ${card.date}
              </span>`
            : ""}

                <span class="card-grid__card-type">${card.mediaType}</span>
            </div>
            <h4 class='card-grid__card-title'>
                ${card.title}
            </h4>
            <p class='card-grid__card-info'>
                ${card.content}
            </p>
            <button class="button button--primary-simple  flex flex--justify-center flex--align-center" disabled>
              <span class="button__label">${linkName}</span>
              <i class="zmdi zmdi-long-arrow-right"></i>
            </button>
            </div>
        `;

        grid.appendChild(cardElement);
        cardIndex++;
      }

      // Add 1 feature card
      if (featureCardIndex < featureCards.length) {
        const card = featureCards[featureCardIndex];
        const linkName = card.linkNameOverride && card.linkNameOverride.trim() !== ""
          ? card.linkNameOverride
          : card.link.name;

        const cardElement = document.createElement("a");
        cardElement.href = card.link.url;
        cardElement.className = "card-grid__card card-grid__card--feature flex flex--column";

        cardElement.innerHTML = `
          ${card.imageUrl ? `
            <div class='card-grid__card-image-container'>
              <img src='${baseURL}${card.imageUrl}' alt='${card.title}' class='card-grid__card-image' />

              ${card.featureTag ? `
                <div class='card-grid__card-badge'>
                  ${card.featureTag}
                </div>`
              : ""}

            </div>`
          : ""}
          <div class='card-grid__card-content flex flex--column flex--align-start'>
          ${card.featureTag && !card.imageUrl ? `
            <div class='card-grid__card-badge' style='position: relative; top: -5px; left: -20px;'>
              ${card.featureTag}
            </div>`
          : ""}
            <div class="card-grid__card-row flex flex--wrap flex--justify-between flex--align-center">

            ${card.date ? `
              <span class='card-grid__card-date'>
                ${card.date}
              </span>`
            : ""}

                <span class="card-grid__card-type">${card.mediaType}</span>
            </div>
            <h4 class='card-grid__card-title'>
                ${card.title}
            </h4>
            <p class='card-grid__card-info'>
                ${card.content}
            </p>
            <button class="button button--primary-simple  flex flex--justify-center flex--align-center" disabled>
              <span class="button__label">${linkName}</span>
              <i class="zmdi zmdi-long-arrow-right"></i>
            </button>
            </div>
        `;

        grid.appendChild(cardElement);
        featureCardIndex++;
      }
    }

    if (cards.length === 0 && featureCards.length === 0) {
      grid.innerHTML = "<span class='flex flex--align-center flex--justify-center card-grid__info'>No results found</span>";
    }
  };

  const getFilters = () => {
    const filters = selectedFilters;
    types = [];
    topics = [];

    filters.forEach(filter => {
      if (filter.category === 0) {
        types.push(filter.name);
      } else if (filter.category === 1) {
        topics.push(filter.name);
      }
    });
  };

  const generateFilterString = (category: string, array: Array<string>) => {
    if (array.length === 0) {
      return "";
    }
    return `&${category}=${array.join(",")}`;
  };

  const createTagListener = () => {
    const tags: NodeListOf<HTMLButtonElement> = cardGrid.querySelectorAll(".filters__tag");

    tags.forEach(tag => {
      tag.addEventListener("click", () => {
        pageNumber = 1;
        fetchData();
      });

      tag.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          pageNumber = 1;
          fetchData();
        }
      });
    });
  };

  const disableFilters = () => {
    const tags: NodeListOf<HTMLButtonElement> = cardGrid.querySelectorAll(".filters__tag");

    labels.forEach(label => {
      label.classList.add("filters__dropdown-label--disabled");
      label.setAttribute("tabindex", "-1");
    });

    tags.forEach((tag: HTMLButtonElement) => {
      tag.disabled = true;
    });
  };

  const enableFilters = () => {
    const tags: NodeListOf<HTMLButtonElement> = cardGrid.querySelectorAll(".filters__tag");

    labels.forEach(label => {
      label.classList.remove("filters__dropdown-label--disabled");
      label.setAttribute("tabindex", "0");
    });

    tags.forEach((tag: HTMLButtonElement) => {
      tag.disabled = false;
    });
  };

  labels.forEach(label => {
    label.addEventListener("click", () => {
      pageNumber = 1;
      fetchData();
      createTagListener();
    });

    label.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        pageNumber = 1;
        fetchData();
        createTagListener();
      }
    });
  });

  if (cardGridTrigger) {
    cardGridTrigger.addEventListener("click", () => {
      cardGridFilters.classList.toggle("card-grid__filters--active");
    });
  }

  paginationElement.addEventListener("click", e => {
    const target = e.target as HTMLElement;

    if (target.tagName === 'BUTTON') {
      const li = target.closest('button');

      if (li.classList.contains('prev')) {
        pageNumber = pageNumber - 1;
      } else if (li.classList.contains('next')) {
        pageNumber = pageNumber + 1;
      } else if (li.classList.contains('pagination__numb')) {
        pageNumber = parseInt(target.textContent);
      }

      disablePagination(paginationElement);
      fetchData();
    }
  });

  fetchData();
}