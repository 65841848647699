import Glide from '@glidejs/glide'

const miniIconsGridCarousel: NodeListOf<HTMLElement> = document.querySelectorAll(".mini-icons-grid__carousel-inner");

miniIconsGridCarousel.forEach(item => {
  const cards: NodeListOf<HTMLElement> = item.querySelectorAll(".mini-icons-grid__carousel-card");
  const carousel = item.parentElement as HTMLElement;

  let mobilePerView = 1.2 as number;

  if (cards.length < 2) {
    mobilePerView = 1;

    carousel.classList.add("mini-icons-grid__carousel--padding-right");
  }

  var glide = new Glide(item, {
    type: 'slider',
    perView: 3.5,
    gap: 20,
    rewind: false,
    breakpoints: {
      1200: {
        perView: 2.5,
      },
      760: {
        perView: 1.7,
      },
      550: {
        perView: mobilePerView,
      },
    }
  })

  glide.mount()

  const miniIconsCarouselRange = item.querySelector(".mini-icons-grid__carousel-range") as HTMLInputElement;
  const slides: NodeListOf<HTMLElement> = item.querySelectorAll(".mini-icons-grid__carousel-card");

  miniIconsCarouselRange.addEventListener("input", e => {
    setTimeout(() => {
      glide.go(`=${miniIconsCarouselRange.value}`)
    }, 500);
  })

  glide.on('move.after', () => {
    const miniIconsCurrentIndex = glide.index;
    miniIconsCarouselRange.value = miniIconsCurrentIndex
  })

  setRangeMax(slides, miniIconsCarouselRange)

  function setRangeMax(arr, input) {
    const slidesLength = Array.from(arr).length
    const rangeMax = (slidesLength - 1).toString()
    input.max = rangeMax

    setRangeThumbWidth(rangeMax)
  }

  function setRangeThumbWidth(max) {
    const miniIconsCarouselRange = item.querySelector(".mini-icons-grid__carousel-range") as HTMLInputElement
    const width = 200 / +max
    miniIconsCarouselRange.style.setProperty('--thumb-size', width.toString() + "px");

    if (max === "1") {
      miniIconsCarouselRange.style.setProperty('--thumb-size', "100px");
    }
  }
})