document.addEventListener("DOMContentLoaded", () => {
  const carousel: HTMLElement | null = document.querySelector('.scroll-carousel');
  const clipped: HTMLElement | null = document.querySelector('.scroll-carousel__clipped');
  let hasScrolledOnce: boolean = false;
  let isInViewport: boolean = false;

  function updateCarouselLogic() {
    const initialTranslate: number = 150;

    if (carousel) {
      carousel.style.transform = `translateX(${initialTranslate}vw)`;
    }
  }

  updateCarouselLogic();

  window.addEventListener('resize', () => {
    updateCarouselLogic();
  });

  function throttle(func: Function, wait: number) {
    let timeout: number | null;
    return function () {
      const context = this, args = arguments;
      if (!timeout) {
        timeout = window.setTimeout(() => {
          func.apply(context, args);
          timeout = null;
        }, wait);
      }
    };
  }

  const handleScroll = throttle(() => {
    if (!isInViewport) return;

    if (!hasScrolledOnce) {
      hasScrolledOnce = true;
      return;
    }

    const scrollPosition: number = window.scrollY;
    const maxScroll: number = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage: number = Math.min(scrollPosition / maxScroll, 1);

    const translateValue: number = 150 - (scrollPercentage * 350); // Directly using 350, instead of translateMultiplier

    if (carousel) {
      carousel.style.transform = `translateX(${translateValue}vw)`;
    }

  }, 20);

  window.addEventListener('scroll', handleScroll);

  const observerOptions: IntersectionObserverInit = {
    root: null,
    threshold: 0.5,
  };

  const fadeInOutObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (clipped) {
          clipped.classList.add('visible');
        }
        isInViewport = true;
      } else {
        if (clipped) {
          clipped.classList.remove('visible');
        }
        isInViewport = false;
      }
    });
  }, observerOptions);

  if (clipped) {
    fadeInOutObserver.observe(clipped);
  }
});
